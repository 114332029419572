/* RecordingButton.css */
/* .div1 {
  display: flex;
  justify-content: center; 
  align-items: center;
 } */
 .div1Left {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333333; /* Change the background color if needed */
  text-align: left;
  padding: 10px; /* Add padding as needed for spacing */
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a visual effect */
  z-index: 1;
}

.div1 {
  display: flex;
  justify-content: space-between; /* Center items horizontally and separate them */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}




 .div3 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 20px;
}

.record-button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .record-button.recording {
    background-color: red;
  }
  
  .record-button:hover {
    background-color: darkred;
  }
  
  .record-button i {
    vertical-align: middle;

  }

.audioCard {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 70vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 20vh;
}

.select-button {
  margin-left: 2vh;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.selected {
  border: 2px solid green; /* Add green border to selected audioCard */
  }

 
.rec{
   margin-right: 2vh;
}
.center-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rec8{
  display: flex;
  align-items: center;
  justify-content: center;
}



