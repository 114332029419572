@tailwind base;
@tailwind components;
@tailwind utilities;




/* body {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100vh;
    background: linear-gradient(#2A00B7, #42006C);

}

code {
    font-family: source-code-pro, Menlo, Monaco, monospace;
} */