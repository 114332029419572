/* Dashboard.css */

.dashboard-container {
    
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    background: #fff;
    padding: 100px;
    width: 80%;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
 
  .logo {
    width: 200px; /* Adjust the size as needed */
    height: 105px;
    margin-left: 410px;
  } 
  
  .user-image {
    border-radius: 100%;
    margin-right: 20px;
    width: 100px; /* Adjust the size as needed */
    height: 100px;
  }
  
  .add-position-button {
    margin: 20px 0;
    text-align: center;
  }
  
  .positions-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .position-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    padding: 30px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .arrow-button {
    background-color: #3498db;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
    justify-content: center;
    align-items: center;
}

.submit {
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 300px;
    height: 59px;
    color: #fff;
    background: #3c009d;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700px;
    cursor: pointer;
    }
    .gray{
        background: #eaeaea;
        color: #676767
    }


.header{
  align-items: start;
}
.submit-next{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  color: #fff;
  background: #3c009d;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700px;
  cursor: pointer;
  }
  .gray{
      background: #eaeaea;
      color: #676767
  }

  .plus{
    padding-left: 30;
  }